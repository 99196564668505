import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.string.small.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('section', {
    class: _vm.$style.HomePage
  }, [_c('s-image-lazy', {
    class: _vm.$style.background,
    attrs: {
      "src": _vm.image,
      "object-fit": "cover",
      "fade": ""
    }
  }), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "appear",
      "appear": ""
    }
  }, [_c('div', {
    class: _vm.$style.formContainer
  }, [_c('h1', {
    staticClass: "h3",
    class: _vm.$style.title
  }, [_vm._v("\n        Авторизация\n      ")]), _vm._v(" "), _c('form', {
    key: "form",
    class: _vm.$style.form,
    attrs: {
      "id": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('s-input', {
    class: _vm.$style.input,
    attrs: {
      "name": "username",
      "autocomplete": "username",
      "label": "Имя пользователя",
      "required": "",
      "disabled": _vm.flags.isLoading,
      "error-message": _vm.errors.username,
      "max-length": 100
    },
    on: {
      "input": function input($event) {
        _vm.errors.username = '';
      },
      "enter": _vm.onSubmit
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _vm._v(" "), _c('s-input', {
    class: _vm.$style.input,
    attrs: {
      "type": "password",
      "name": "password",
      "label": "Пароль",
      "autocomplete": "current-password",
      "required": "",
      "disabled": _vm.flags.isLoading,
      "error-message": _vm.errors.password,
      "error": _vm.errors.password !== ''
    },
    on: {
      "input": function input($event) {
        _vm.errors.password = '';
      },
      "enter": _vm.onSubmit
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm._v(" "), _c('s-button', {
    class: _vm.$style.btn,
    attrs: {
      "disabled": _vm.flags.isLoading,
      "loading": _vm.flags.isLoading,
      "type": "submit",
      "size": "x-large"
    }
  }, [_vm._v("\n          Войти\n        ")]), _vm._v(" "), _c('small', {
    staticClass: "text",
    class: _vm.$style.small
  }, [_vm._v("\n          Нажимая на кнопку «Войти», вы даете согласие на обработку своих\n          персональных данных\n        ")])], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };