// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomePage_gNTHn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;height:100vh;justify-content:center}.background_5cEuj{height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;position:absolute;width:100%}.formContainer_HVLd1{background-color:#fcfcfc;border-radius:.8rem;height:auto;padding:5.2rem;position:relative;width:41.5rem}.input_9O5dP,.title_N9OrL{margin-bottom:3.2rem}.input_9O5dP{-webkit-transition:margin-bottom .4s ease-in-out;transition:margin-bottom .4s ease-in-out}.input_9O5dP.s-input--error{margin-bottom:5.2rem}.btn_L2jCe{width:100%}.small_a2Feq{color:#677b8f;display:block;font-size:1.2rem;margin-top:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomePage": "HomePage_gNTHn",
	"background": "background_5cEuj",
	"formContainer": "formContainer_HVLd1",
	"input": "input_9O5dP",
	"title": "title_N9OrL",
	"btn": "btn_L2jCe",
	"small": "small_a2Feq"
};
module.exports = ___CSS_LOADER_EXPORT___;
